import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/user/superAdmin',
    method : 'post',
    data : data
  })
}

//新增
export function createAdmin(data) {
  return request({
    url : '/user/createAdmin',
    method : 'post',
    data : data
  })
}
//修改
export function updateAdmin(data) {
  return request({
    url :'/user/updateAdmin',
    method : 'post',
    data : data
  })
}


//删除
export function deleteAdmin(data) {
  return request({
    url : '/user/deleteUser',
    method : 'post',
    data : data
  })
}

//批量删除
export function batchDeleteAdmin(data) {
  return request({
    url : '/user/batchDeleteUser',
    method : 'post',
    data : data
  })
}

//重置管理员密码
export function resetAdminPass(data) {
  return request({
    url : '/user/resetUserPass',
    method : 'post',
    data : data
  })
}

//冻结账号
export function freezeAdmin(data) {
  return request({
    url : '/user/freezeAdmin',
    method : 'post',
    data : data
  })
}

//解冻账号
export function thawAdmin(data) {
  return request({
    url : '/user/thawAdmin',
    method : 'post',
    data : data
  })
}

//批量设置过期时间
export function batchAddAdminEndTime(data) {
  return request({
    url : '/user/batchAddAdminEndTime',
    method : 'post',
    data : data
  })
}
