<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="用户名：">
            <el-input style="width: 230px" v-model="listQuery.keyword" placeholder="请输入用户名" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 55px;">
        <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
        <el-button size="small" @click="handleResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!--    功能区域-->
    <div style="margin: 20px 0">
      <el-button type="primary" @click="handleAdd" >添加</el-button>
      <el-button type="danger" @click="deleteBatch" >批量删除</el-button>
      <!--      <el-button type="primary" @click="handleBatchEndTime" >批量设置过期时间</el-button>-->
    </div>
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="adminTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="120" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="用户名"  align="center">
          <template #default="scope">{{scope.row.username}}</template>
        </el-table-column>
        <el-table-column label="手机号" align="center">
          <template #default="scope">{{scope.row.mobile}}</template>
        </el-table-column>
        <el-table-column label="昵称" align="center">
          <template #default="scope">{{scope.row.nickName}}</template>
        </el-table-column>
        <el-table-column label="有效期" align="center" width="300">
          <template #default="scope"><span style="margin-right: 10px;">{{scope.row.strStartTime}}</span> —— <span style="margin-left: 10px;">{{scope.row.strEndTime}}</span></template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="100">
          <template #default="scope">{{scope.row.status === 1 ? '正常' : '已冻结' }}</template>
        </el-table-column>
        <el-table-column label="操作" width="450" align="center">
          <template #default="scope">
            <el-button size="mini" type="primary" plain @click="handleUpdate(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" >删除</el-button>
            <el-button size="mini" type="primary" v-if="scope.row.status === 1" @click="handleFreeze(scope.row)" >冻结账号</el-button>
            <el-button size="mini" type="primary" v-if="scope.row.status === 0" @click="handleThaw(scope.row)" >解除账号</el-button>
            <el-button size="mini" type="primary" @click="handlePass(scope.row)" >重置密码</el-button>
            <el-button size="mini" type="primary" @click="handleEndTime(scope.row)" >设置有效期</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEditFlag == true?'编辑':'添加'"
        v-model="dialogVisible"
        width="40%">
      <el-form :model="adminModel"
               :rules="rules"
               ref="adminFrom"
               label-width="150px"
               size="small">
        <el-form-item label="用户名：" prop="username">
          <el-input v-model="adminModel.username" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="昵称：" >
          <el-input v-model="adminModel.nickName" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号："  prop="mobile">
          <el-input v-model="adminModel.mobile" style="width: 80%" clearable></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleDialogConfirm('adminFrom')">提交</el-button>
          </span>
      </template>
    </el-dialog>

    <el-dialog
        title="设置过期时间"
        v-model="dialogVisibleEndTime"
        width="40%">
      <el-form :model="adminModel"
               ref="adminFrom"
               label-width="150px"
               size="small">
        <el-form-item label="截止时间：">
          <el-date-picker v-model="adminModel.endTime" value-format="YYYY-MM-DD HH:mm:ss"
                          format="YYYY-MM-DD HH:mm:ss" type="datetime"  style="width:50%" clearable></el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisibleEndTime = false">取 消</el-button>
            <el-button type="primary" @click="handleBatchAddEndTimeConfirm('adminFrom')">确定</el-button>
          </span>
      </template>
    </el-dialog>

  </div>



</template>

<script>


import {
  batchDeleteAdmin,
  deleteAdmin,
  fetchList,
  updateAdmin,
  createAdmin,
  resetAdminPass,
  batchAddAdminEndTime, freezeAdmin, thawAdmin
} from "@/api/admin";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
};
const defaultAdmin = {
  username: null,
  nickName: null,
  mobile: null,
  endTime: null,
};


export default {
  name: 'Admin',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        mobile: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
        ],
      },
      dialogVisible: false,
      dialogVisibleEndTime: false,
      isEditFlag: false,
      adminModel: Object.assign({}, defaultAdmin),
    }
  },
  created() {
    this.getTableList()
  },
  methods: {

    //重置
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
      this.getTableList();
    },
    //查询
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },

    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAdmin(row.id).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        batchDeleteAdmin(this.ids).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "批量删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //列表
    getTableList() {
      this.listLoading = true;
      fetchList(this.listQuery).then(res => {
        if (res.code === '0') {
              this.listLoading = false;
              this.tableData = res.data.records;
              this.total = res.data.total;
        }
      })
    },
    //添加路由
    handleAdd() {
      this.dialogVisible = true;
      this.isEditFlag = false;
      this.adminModel = Object.assign({},defaultAdmin);
    },
    //修改路由
    handleUpdate(row) {
      this.dialogVisible = true;
      this.isEditFlag = true;
      this.adminModel = Object.assign({},row);
    },

    //批量设置过期时间
    handleBatchEndTime() {
      if (!this.ids.length) {
        this.$message.warning("请先选择数据！")
        return
      }
      this.dialogVisibleEndTime = true;
    },
    //修改过期时间
    handleEndTime(row) {
      this.ids = [];
      this.dialogVisibleEndTime = true;
      this.adminModel = Object.assign({},row);
    },
    //批量设置过期时间
    handleBatchAddEndTimeConfirm() {
      if(this.adminModel.id){
        this.ids.push(this.adminModel.id);
      }

      if (!this.ids.length) {
        this.$message.warning("请先选择数据！")
        return
      }

      this.$confirm('确定设置吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": this.ids,
          "endTime": this.adminModel.endTime,
        };

        batchAddAdminEndTime(data).then(res => {
          if (res.code === '0') {
            this.$message({
              message: '设置成功',
              type: 'success',
            });
          }else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.dialogVisibleEndTime =false;
          this.getTableList();
        });

      });
    },


    //确认操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.isEditFlag) {
              updateAdmin(this.adminModel).then(res => {
                if (res.code === '0') {
                  this.$message({
                    message: '修改成功',
                    type: 'success',
                  });
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });

            }else {
              createAdmin(this.adminModel).then(res => {
                if (res.code === '0') {
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });
            }
          });
        }
      });
    },
    //重置密码
    handlePass(row) {
      let user = JSON.parse(sessionStorage.getItem("user"))
      if(user.id == row.id){
        this.$message({
          type: "error",
          message: "禁止重置当前登录用户"
        })
        return
      }
      if(row.id == 1){
        this.$message({
          type: "error",
          message: "超级管理员禁止重置"
        })
        return
      }
      this.$confirm('确定重置吗？重置后的密码是：123456', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        resetAdminPass(row.id).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "密码重置成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //冻结账号
    handleFreeze(row) {
      let user = JSON.parse(sessionStorage.getItem("user"))
      if(user.id == row.id){
        this.$message({
          type: "error",
          message: "禁止冻结当前登录用户"
        })
        return
      }
      if(row.id == 1){
        this.$message({
          type: "error",
          message: "超级管理员禁止冻结"
        })
        return
      }
      this.$confirm('确定冻结吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        freezeAdmin(row.id).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "冻结成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //解除账号
    handleThaw(row) {
      let user = JSON.parse(sessionStorage.getItem("user"))
      if(user.id == row.id){
        this.$message({
          type: "error",
          message: "禁止操作当前登录用户"
        })
        return
      }
      if(row.id == 1){
        this.$message({
          type: "error",
          message: "超级管理员禁止操作"
        })
        return
      }
      this.$confirm('确定解除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        thawAdmin(row.id).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "解除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },

  }
}
</script>
